import React from "react";

const Placeholder = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: 0,
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f0f0f0",
      }}
    >
      <h1 style={{ color: "#333", fontSize: "3em" }}>
        Story Starter: Where Stories Begin and Creativity Thrives!
      </h1>
    </div>
  );
};

export default Placeholder;
