import {
  faBook,
  faFile,
  faLightbulb,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";

const Sidebar = () => {
  return (
    <Nav defaultActiveKey="/" className="flex-column">
      <Nav.Item>
        <Nav.Link href="/dashboard">
          <FontAwesomeIcon icon={faLightbulb} className="me-2" />
          Dashboard
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/books">
          <FontAwesomeIcon icon={faBook} className="me-2" />
          Books
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/snippets">
          <FontAwesomeIcon icon={faFile} className="me-2" />
          Snippets
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/expander">
          <FontAwesomeIcon icon={faUpDownLeftRight} className="me-2" />
          Expander
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default Sidebar;
