// src/components/LogIn.tsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthUrl } from "../auth";

const LogIn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const initiateLogin = async () => {
      try {
        const authUrl = await getAuthUrl();
        window.location.href = authUrl;
      } catch (error) {
        console.error("Error during login initiation:", error);
        navigate("/error"); // Redirect to an error page if login fails
      }
    };

    initiateLogin();
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <p>Redirecting you to login...</p>
    </div>
  );
};

export default LogIn;
